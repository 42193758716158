<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
              <symbol
                id="check-circle-fill"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                />
              </symbol>
              <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                <path
                  d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </symbol>
              <symbol
                id="exclamation-triangle-fill"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                />
              </symbol>
            </svg>
            <div
              class="alert alert-warning"
              role="alert"
              v-show="this.refProspectCustomer !== null"
            >
              <div class="row">
                <div class="col-md-9">
                  <svg
                    class="bi flex-shrink-0 me-2"
                    width="20"
                    height="20"
                    role="img"
                    aria-label="Warning:"
                  >
                    <use xlink:href="#exclamation-triangle-fill" />
                  </svg>
                  <b>(ซ้ำ)</b> &nbsp;
                  <b>{{ this.refNameTh }}&nbsp; {{ this.refFamilyNameTh }}</b>
                  &nbsp;จากสาขา&nbsp;
                  <b>{{ this.refBranchNameTh }}</b>
                  เบอร์โทร
                  <b>{{ this.refPhone }}</b>
                  &nbsp;
                  <span v-if="this.refEmail !== null">อีเมล</span> &nbsp;
                  <b>{{ this.refEmail }}</b>
                </div>

                <!-- <div class="col-md-3 text-end">
                  <span
                    class="badge bg-warning text-dark"
                    v-if="this.status == 'A'"
                    >กำลังติดตาม</span
                  >
                  <span class="badge bg-success" v-if="this.status == 'C'"
                    >เสร็จสิ้น</span
                  >
                </div> -->
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="card-title">ข้อมูลลูกค้า</h4>
                </div>
                <div class="col-md-6 text-end">
                  <span
                    class="badge bg-warning text-dark font-size-12"
                    v-if="this.status == 'A'"
                    >กำลังติดตาม</span
                  >
                  <span
                    class="badge bg-success font-size-12"
                    v-if="this.status == 'C'"
                    >เสร็จสิ้น(ซื้อ)</span
                  >
                </div>
              </div>

              <div class="row mt-3">
                <form-wizard color="#5b73e8" step-size="sm">
                  <tab-content icon="mdi mdi-numeric-1" title="ข้อมูลส่วนตัว">
                    <div class="row">
                      <div class="col-sm-6 col-md-6 col-xl-5">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="branchId">สาขา:</label>
                          <multiselect
                            disabled
                            id="branchId"
                            v-model="form.branchId"
                            :options="localData"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @input="getSearch()"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <code>* </code
                          ><label for="mobilePhone">เบอร์โทรศัพท์:</label>
                          <input
                            maxlength="10"
                            v-model="form.mobilePhone"
                            type="tel"
                            class="form-control input-placeholder"
                            placeholder="เบอร์โทรศัพท์แบบไม่มีขีด"
                            :class="{
                              'is-invalid':
                                submitform && $v.form.mobilePhone.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.form.mobilePhone.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.mobilePhone.required ||
                                !$v.form.mobilePhone.numeric ||
                                !$v.form.mobilePhone.maxLength ||
                                !$v.form.mobilePhone.minLength
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="gender"> เพศ:</label>
                          <multiselect
                            id="gender"
                            v-model="form.gender"
                            :options="genderOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            placeholder=""
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="prefixOPtion">คำนำหน้า:</label>
                          <multiselect
                            placeholder=""
                            @input="ChangeNameTh(form.prefixId)"
                            v-model="form.prefixId"
                            :options="prefixOPtion"
                            label="nameTh"
                            :show-labels="false"
                          >
                          </multiselect>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="nameTh">ชื่อ:</label>
                          <input
                            v-model="form.nameTh"
                            type="text"
                            class="form-control"
                            placeholder=""
                            :class="{
                              'is-invalid': submitform && $v.form.nameTh.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.form.nameTh.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.nameTh.required ||
                                !$v.form.nameTh.maxLength
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltiplame">นามสกุล:</label>
                          <input
                            v-model="form.familyNameTh"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <code>* </code
                          ><label for="cusSrcId"> แหล่งที่มา:</label>
                          <multiselect
                            id="cusSrcId"
                            v-model="form.cusSrcId"
                            :options="cusSrcOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="cusSrcId"
                            placeholder=""
                            @search-change="getCustomerSource($event)"
                            :class="{
                              'is-invalid':
                                submitform && $v.form.cusSrcId.$error,
                            }"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                          <div
                            v-if="submitform && $v.form.cusSrcId.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.form.cusSrcId.required">{{
                              error
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="birthDate">วันเกิด:</label>

                          <date-picker
                            v-model="form.birthDate"
                            :first-day-of-week="1"
                            format="YYYY-MM-DD"
                            value-type="format"
                            lang="en"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-md-2 col-sm-6 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="age">อายุ:</label>
                          <input
                            v-model="form.age"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="citizenId">เลขบัตรประชาชน:</label>
                          <input
                            maxlength="13"
                            v-model="form.citizenId"
                            type="text"
                            class="form-control"
                            placeholder="เลขบัตรประชาชน 13 หลัก"
                            :class="{
                              'is-invalid':
                                submitform && $v.form.citizenId.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.form.citizenId.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.citizenId.minLength ||
                                !$v.form.citizenId.maxLength ||
                                !$v.form.citizenId.numeric
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipemail">อีเมล:</label>
                          <input
                            v-model="form.email"
                            type="email"
                            class="form-control input-placeholder"
                            placeholder="ตัวอย่าง : example@mail.com"
                            :class="{
                              'is-invalid': submitform && $v.form.email.$error,
                            }"
                          />
                          <div
                            v-if="submitform && $v.form.email.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.form.email.email">{{ error }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipenflname"
                            >Facebook:</label
                          >
                          <input
                            v-model="form.facebookId"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipenflname">Line:</label>
                          <input
                            v-model="form.lineId"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="customerType"> ประเภทลูกค้า:</label>
                          <multiselect
                            id="customerType"
                            v-model="form.customerType"
                            :options="customerTypeOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="id"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="lifestyleId"> ไลฟสไตล์:</label>
                          <multiselect
                            id="lifestyleId"
                            v-model="form.lifestyleId"
                            :options="lifestyleOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="lifestyleId"
                            @search-change="getLifestyle($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="occId"> สถานะ:</label>
                          <multiselect
                            id="occId"
                            v-model="form.maritalStatus"
                            :options="maritalOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="id"
                            placeholder=""
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-3 col-xl-2">
                        <div class="mb-3 position-relative">
                          <label for="children">บุตร:</label>
                          <input
                            v-model="form.children"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-2">
                            <label class="form-label d-block mb-3"
                              >ยินยอมให้ช้ข้อมูลส่วนตัว:</label
                            >

                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentPrivate"
                                id="consentPrivate1"
                                name="outer-group[0][consentPrivate]"
                                class="mb-3"
                                value="1"
                                plain
                                >ยินยอม</b-form-radio
                              >
                            </div>
                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentPrivate"
                                id="consentPrivate2"
                                name="outer-group[1][consentPrivate]"
                                class="mb-3"
                                value="0"
                                plain
                                >ไม่ยินยอม</b-form-radio
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-2">
                            <label class="form-label d-block mb-3"
                              >ยินยอมให้ใช้ข้อมูลสำหรับทำการตลาด:</label
                            >

                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentMarketing"
                                id="consentMarketing1"
                                name="outer-group[0][consentMarketing]"
                                class="mb-3"
                                value="1"
                                plain
                                >ยินยอม</b-form-radio
                              >
                            </div>
                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentMarketing"
                                id="consentMarketing2"
                                name="outer-group[1][consentMarketing]"
                                class="mb-3"
                                value="0"
                                plain
                                >ไม่ยินยอม</b-form-radio
                              >
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                          <div class="mb-2">
                            <label class="form-label d-block mb-3"
                              >ยินยอมให้ใช้ข้อมูลสำคัญ:</label
                            >

                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentSensitive"
                                id="consentSensitive1"
                                name="outer-group[0][consentSensitive]"
                                class="mb-3"
                                value="1"
                                unchecked-value="0"
                                plain
                                >ยินยอม</b-form-radio
                              >
                            </div>
                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentSensitive"
                                id="consentSensitive2"
                                name="outer-group[1][consentSensitive]"
                                class="mb-3"
                                value="0"
                                plain
                                >ไม่ยินยอม</b-form-radio
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-sm-4">
                          <div class="mb-2">
                            <label class="form-label d-block mb-3"
                              >ยินยอมให้บริษัทพันธมิตรใช้ข้อมูล:</label
                            >

                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentTransfer"
                                id="consentTransfer1"
                                name="outer-group[0][consentTransfer]"
                                class="mb-3"
                                value="1"
                                plain
                                >ยินยอม</b-form-radio
                              >
                            </div>
                            <div
                              class="custom-radio form-check form-check-inline"
                            >
                              <b-form-radio
                                v-model="form.consentTransfer"
                                id="consentTransfer2"
                                name="outer-group[1][consentTransfer]"
                                class="mb-3"
                                value="0"
                                plain
                                >ไม่ยินยอม</b-form-radio
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 position-relative">
                          <label for="firstContactNote"
                            >หมายเหตุในการติดต่อครั้งแรก:</label
                          >

                          <textarea
                            v-model="form.firstContactNote"
                            id="formmessage"
                            rows="3"
                            class="form-control"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>
                    <!-- end col -->

                    <!-- end row -->
                  </tab-content>
                  <tab-content
                    icon="mdi mdi-numeric-2"
                    title="ข้อมูลรายได้"
                    aria-selected="true"
                    aria-disabled="true"
                  >
                    <div class="row">
                      <div class="col-sm-6 col-md-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="srcInfoId">
                            ได้รับข้อมูลจากแหล่งใด:</label
                          >
                          <multiselect
                            id="srcInfoId"
                            v-model="form.srcInfoId"
                            :options="srcInOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getSrcIn($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="salary"> ช่วงรายได้:</label>
                          <multiselect
                            id="salary"
                            v-model="form.salaryId"
                            :options="salarysOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getSalary($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <!-- <div class="col-sm-6 col-md-3 col-xxl-2">
                      <div class="mb-3 position-relative">
                        <label for="salary"> รายได้:</label>
                        <multiselect
                          id="salary"
                          v-model="form.salary"
                          :options="salaryOption"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div> -->
                      <div class="col-sm-6 col-md-4 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="familySalaryId">
                            ช่วงรายได้ของครอบครัว:</label
                          >
                          <multiselect
                            id="familySalaryId"
                            v-model="form.familySalaryId"
                            :options="familySalaryOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getFamilySalary($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="occId"> อาชีพ:</label>
                          <multiselect
                            id="occId"
                            v-model="form.occId"
                            :options="occOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="occId"
                            @search-change="getOccupations($event)"
                          >
                            <span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipenflname"
                            >สถานที่ทำงาน:</label
                          >
                          <input
                            v-model="form.workPlace"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="regPlace"> สถานที่ลงทะเบียน:</label>
                          <multiselect
                            id="regPlace"
                            v-model="form.regPlace"
                            :options="regPlaceOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="eventDate">วันที่จัดอีเวนต์:</label>

                          <date-picker
                            v-model="form.eventDate"
                            :first-day-of-week="1"
                            format="YYYY-MM-DD"
                            value-type="format"
                            lang="en"
                          >
                          </date-picker>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-xl-5 col-xxl-4">
                        <div class="mb-3 position-relative">
                          <label for="eventId"> อีเวนต์:</label>
                          <multiselect
                            id="eventId"
                            v-model="form.eventId"
                            :options="eventOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getEvent($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="objId"> จุดประสงค์การซื้อ:</label>
                          <multiselect
                            id="objId"
                            v-model="form.objId"
                            :options="objOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="objId"
                            @search-change="getObj($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6 col-xl-3 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="croId"> CRO:</label>
                          <multiselect
                            id="croId"
                            v-model="form.croId"
                            :options="croOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            :custom-label="customLabelCro"
                            @search-change="getCro($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row -->
                  </tab-content>
                  <tab-content
                    icon="mdi mdi-numeric-3"
                    title="ข้อมูลรถที่เคยใช้"
                  >
                    <div class="row">
                      <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="usedBrand">
                            ยี่ห้อที่ใช้ในปัจจุบัน:</label
                          >
                          <multiselect
                            id="usedBrand"
                            v-model="form.usedBrand"
                            :options="usedBrandOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="brandId"
                            @search-change="getUsedBrand($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="otherModelId"> รุ่นอื่นๆ ที่สนใจ:</label>
                          <multiselect
                            id="otherModelId"
                            v-model="form.otherModelId"
                            :options="otherModelOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                            @search-change="getOtherModel($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="otherVehicleTypeId">
                            ประเภทรถอื่นๆ ที่สนใจ:</label
                          >
                          <multiselect
                            id="otherVehicleTypeId"
                            v-model="form.otherVehicleTypeId"
                            :options="otherVehicleOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="typeId"
                            @search-change="getOtherVehicle($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-5 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="otherVehicleSubTypeId">
                            ประเภทย่อยของรถอื่นๆ ที่สนใจ:</label
                          >
                          <multiselect
                            id="otherVehicleSubTypeId"
                            v-model="form.otherVehicleSubTypeId"
                            :options="otherVehicleSubOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="subTypeId"
                            @search-change="getOtherVehicleSub($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-4 col-xxl-3">
                        <div class="mb-3 position-relative">
                          <label for="usedVehicleTypeId">
                            ประเภทรถที่ใช้ในปัจจุบัน:</label
                          >
                          <multiselect
                            id="usedVehicleTypeId"
                            v-model="form.usedVehicleTypeId"
                            :options="usedVehicleOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="typeId"
                            @search-change="getUsedVehicle($event)"
                            ><span slot="noResult"
                              >ไม่พบข้อมูล</span
                            ></multiselect
                          >
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="age">ใช้งานรถมาแล้ว (ปี):</label>
                          <input
                            v-model="form.vehicleLifeYear"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-xl-3 col-xxl-2">
                        <div class="mb-3 position-relative">
                          <label for="vehicleLifeMonth"
                            >ใช้งานรถมาแล้ว (เดือน):</label
                          >
                          <input
                            v-model="form.vehicleLifeMonth"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6 col-md-3 col-xxl-2">
                          <label class="mb-2">ระดับความสนใจ:</label>

                          <b-form-radio-group
                            v-model="form.level"
                            name="radios-stacked"
                            stacked
                          >
                            <b-form-radio value="3"
                              ><span class="ms-2"> สูง</span></b-form-radio
                            >
                            <b-form-radio value="2"
                              ><span class="ms-2"> กลาง</span></b-form-radio
                            >
                            <b-form-radio value="1"
                              ><span class="ms-2"> ต่ำ</span></b-form-radio
                            >
                            <b-form-radio value="0"
                              ><span class="ms-2"> อื่นๆ</span></b-form-radio
                            >
                          </b-form-radio-group>
                        </div>
                        <div class="col-sm-6 col-md-3 col-xxl-2">
                          <label class="mb-2">ความพร้อมในการซื้อ:</label>

                          <b-form-radio-group
                            v-model="form.readiness"
                            name="radios-stackeds"
                            stacked
                          >
                            <b-form-radio value="3"
                              ><span class="ms-2"> สูง</span></b-form-radio
                            >
                            <b-form-radio value="2"
                              ><span class="ms-2"> กลาง</span></b-form-radio
                            >
                            <b-form-radio value="1"
                              ><span class="ms-2"> ต่ำ</span></b-form-radio
                            >
                            <b-form-radio value="0"
                              ><span class="ms-2">
                                ยังไม่พร้อม</span
                              ></b-form-radio
                            >
                          </b-form-radio-group>
                        </div>
                      </div>
                    </div>
                    <!-- end row -->
                  </tab-content>
                  <tab-content icon="mdi mdi-numeric-4" title="ข้อมูลอื่นๆ">
                    <div class="row">
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="occId"> ประเภทที่อยู่อาศัย:</label>
                          <multiselect
                            id="occId"
                            v-model="form.residentType"
                            :options="residentOption"
                            :show-labels="false"
                            label="nameTh"
                            track-by="id"
                          ></multiselect>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipenflname"
                            >เลขทะเบียนบ้าน:</label
                          >
                          <input
                            v-model="form.houseRegistration"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <div class="mb-3 position-relative">
                          <label for="buildingNumber">บ้านเลขที่:</label>
                          <input
                            id="buildingNumber"
                            v-model="form.buildingNumber"
                            type="text"
                            class="form-control"
                            value=""
                          />
                          <span class="text-muted"
                            ><strong><code>999/999</code></strong></span
                          >
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <div class="mb-3 position-relative">
                          <label for="townshipNumber">หมู่:</label>
                          <input
                            :maxLength="10"
                            :minlength="1"
                            id="townshipNumber"
                            v-model="form.townshipNumber"
                            type="text"
                            class="form-control"
                            value=""
                          />
                          <span class="text-muted"
                            ><strong><code>ตัวอย่าง 1,2,3</code></strong></span
                          >
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="townshipName">ชื่อหมู่บ้าน:</label>
                          <input
                            id="townshipName"
                            v-model="form.townshipName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="subLane">ตรอก:</label>
                          <input
                            id="subLane"
                            v-model="form.subLane"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="lane">ซอย:</label>
                          <input
                            id="lane"
                            v-model="form.lane"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="streetName">ถนน:</label>
                          <input
                            id="streetName"
                            v-model="form.streetName"
                            type="text"
                            class="form-control"
                            value=""
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="provinceId">จังหวัด:</label>
                          <multiselect
                            @input="getAmphures(provinceId)"
                            id="provinceId"
                            v-model="provinceId"
                            :options="provinces"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="amphurId">อำเภอ:</label>
                          <multiselect
                            @input="getDistricts(amphurId)"
                            id="amphurId"
                            v-model="amphurId"
                            :options="amphures"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="districtId">ตำบล:</label>
                          <multiselect
                            id="districtId"
                            v-model="districtId"
                            :options="districts"
                            :show-labels="false"
                            label="nameTh"
                            track-by="nameTh"
                          ></multiselect>
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="postCode">รหัสไปรษณีย์:</label>
                          <input
                            id="postCode"
                            v-model="form.postCode"
                            maxlength="5"
                            type="text"
                            class="form-control"
                            value=""
                            :class="{
                              'is-invalid':
                                submitform && $v.form.postCode.$error,
                            }"
                          />

                          <div
                            v-if="submitform && $v.form.postCode.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.form.postCode.numeric ||
                                !$v.form.postCode.minLength ||
                                !$v.form.postCode.maxLength
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 position-relative">
                          <label for="fullAddress">ที่อยู่เต็ม:</label>
                          <!-- disabled="disabled"  -->
                          <textarea
                            disabled
                            v-model="fullAddress"
                            class="form-control"
                            name="textarea"
                            rows="3"
                          ></textarea>
                          <p></p>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="mb-3 position-relative">
                          <label for="validationTooltipnote">หมายเหตุ:</label>

                          <textarea
                            v-model="form.note"
                            id="formmessage"
                            rows="3"
                            class="form-control"
                          >
                          </textarea>
                        </div>
                      </div>
                    </div>

                    <!-- end row -->
                  </tab-content>
                  <template slot="footer" slot-scope="props">
                    <div class="wizard-footer-left">
                      <button
                        v-if="props.activeTabIndex > 0"
                        :style="props.fillButtonStyle"
                        @click="props.prevTab()"
                        class="btn"
                      >
                        ย้อนกลับ
                      </button>
                    </div>
                    <div class="wizard-footer-right">
                      <button
                        v-if="!props.isLastStep"
                        @click="validateNextTab(props)"
                        class="btn"
                        :style="props.fillButtonStyle"
                      >
                        ต่อไป
                      </button>
                    </div>
                    <div class="wizard-footer-right">
                      <button
                        type="submit"
                        class="btn btn-success"
                        @click="Form"
                      >
                        บันทึก
                      </button>
                      &nbsp;
                    </div>
                  </template>
                </form-wizard>
                <!-- <div class="text-end br">
                  <button type="submit" class="btn btn-success" @click="Form">
                    บันทึก
                  </button>
                  &nbsp;
                </div> -->
              </div>
            </div>

            <!-- <div class="row">
            <div class="col-md-12 text-end">
             
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div> -->
            <hr />
          </div>
          <!-- //!end card -->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h4 class="card-title">ข้อมูลรถ</h4>
                </div>
                <div class="col-md-6 text-end">
                  &nbsp;
                  <button class="btn btn-primary" v-b-modal.modal-ve>
                    เพิ่มข้อมูลรถ
                  </button>
                </div>
              </div>
              <br />
              <b-modal
                ref="modal-ve"
                id="modal-ve"
                title="เพิ่มข้อมูลรถ"
                hide-footer
                size="lg"
                centered
              >
                <div class="card-body">
                  <form class="needs-validation" @submit.prevent="vehicleForm">
                    <div class="row">
                      <h5 class="font-size-14 mb-1">ข้อมูลรถที่ต้องการ</h5>
                      <hr />
                      <div class="col-md-12 col-sm-12">
                        <div class="row align-items-center">
                          <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-3 position-relative">
                              <label for="prodId">สินค้า:</label>
                              <multiselect
                                id="prodId"
                                v-model="vehicleform.prodId"
                                :options="proOption"
                                style="ts-15"
                                placeholder=""
                                label="nameTh"
                                :show-labels="false"
                                track-by="prodId"
                                :loading="loading"
                                :close-on-select="true"
                                :internal-search="true"
                                open-direction="bottom"
                                :custom-label="customLabelPro"
                                @search-change="getProduct($event)"
                                @input="selectOb(vehicleform.prodId)"
                                :class="{
                                  'is-invalid':
                                    submitVehicleForm &&
                                    $v.vehicleform.prodId.$error,
                                }"
                              >
                                <span slot="noResult">ไม่พบข้อมูล</span>
                              </multiselect>
                              <div
                                v-if="
                                  submitVehicleForm &&
                                  $v.vehicleform.prodId.$error
                                "
                                class="invalid-tooltip"
                              >
                                <span v-if="!$v.vehicleform.prodId.required">
                                  {{ error }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="provinceId">สีภายนอก:</label>
                          <multiselect
                            v-model="vehicleform.exteriorColorId"
                            track-by="exteriorColorId"
                            :options="exteriorColorOption"
                            label="nameTh"
                            :show-labels="false"
                            :custom-label="customLabelExColer"
                            @search-change="getExteriorColor($event)"
                          >
                            <span slot="noResult">ไม่พบข้อมูล</span>
                          </multiselect>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="provinceId">สีภายใน:</label>
                          <multiselect
                            v-model="vehicleform.interiorColorId"
                            track-by="interiorColorId"
                            :options="interiorColorOption"
                            label="nameTh"
                            :show-labels="false"
                            :custom-label="customLabelInColer"
                            @search-change="getInteriorColor($event)"
                          >
                            <span slot="noResult">ไม่พบข้อมูล</span>
                          </multiselect>
                        </div>
                      </div>
                      <!-- <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="gearSystemId">เกียร์:</label>
                          <multiselect
                            v-model="vehicleform.gearSystemId"
                            track-by="nameTh"
                            :options="gearSystemOption"
                            label="nameTh"
                            :show-labels="false"
                            @search-change="getGearSystems($event)"
                          >
                            <span slot="noResult">ไม่พบข้อมูล</span>
                          </multiselect>
                        </div>
                      </div> -->
                      <div class="col-md-12 col-sm-12">
                        <div class="mb-3 position-relative">
                          <label for="note">หมายเหตุ:</label>

                          <textarea
                            v-model="vehicleform.note"
                            id="formmessage"
                            rows="3"
                            class="form-control"
                          >
                          </textarea>
                        </div>
                      </div>
                      <h5 class="font-size-14 mb-1">ข้อมูลการเงิน</h5>
                      <hr />
                      <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <code>* </code
                          ><label for="provinceId">ประเภทการชำระ:</label>
                          <multiselect
                            v-model="vehicleform.saleCondition"
                            track-by="nameTh"
                            :options="saleConditionOption"
                            label="nameTh"
                            :show-labels="false"
                            :class="{
                              'is-invalid':
                                submitVehicleForm &&
                                $v.vehicleform.saleCondition.$error,
                            }"
                            @select="dis"
                          >
                          </multiselect>
                          <div
                            v-if="
                              submitVehicleForm &&
                              $v.vehicleform.saleCondition.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.vehicleform.saleCondition.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="price">ราคา:</label>
                          <input
                            :disabled="isDisabled"
                            v-model="vehicleform.price"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="downPayment">เงินดาวน์(%):</label>
                          <input
                            :disabled="isDisabled"
                            v-model="vehicleform.downPaymentPer"
                            type="text"
                            class="form-control"
                            placeholder=""
                            @input="calculate()"
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="downPayment">เงินดาวน์(บาท):</label>
                          <input
                            :disabled="isDisabled"
                            v-model="vehicleform.downPayment"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="installmentPeriod">จำนวนงวด:</label>
                          <input
                            :disabled="isDisabled"
                            v-model="vehicleform.installmentPeriod"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="col-md-4 col-sm-6">
                        <div class="mb-3 position-relative">
                          <label for="installmentPayment">ค่างวด:</label>
                          <input
                            :disabled="isDisabled"
                            v-model="vehicleform.installmentPayment"
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="text-end">
                      <button type="submit" class="btn btn-success">
                        บันทึก
                      </button>
                    </div>
                  </form>
                </div>
              </b-modal>

              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ลำดับ</th>
                        <th scope="col" class="text-center">สินค้า</th>
                        <th scope="col" class="text-center">ยี่ห้อ</th>
                        <th scope="col" class="text-center">รุ่น</th>
                        <th scope="col" class="text-center">ประเภทการชำระ</th>
                        <th scope="col" class="text-center">ราคา</th>
                        <th scope="col" class="text-center">เงินดาวน์(%)</th>
                        <th scope="col" class="text-center">เงินดาวน์(บาท)</th>
                        <th scope="col" class="text-center">จำนวนงวด</th>
                        <th scope="col" class="text-center">ค่างวด</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    <tbody v-for="(item, index) in rowVehicle" :key="index">
                      <tr>
                        <th scope="row">{{ index + 1 }}</th>

                        <td>
                          {{ item.productNameTh }}
                        </td>
                        <td>
                          {{ item.vehicleBrandTh }}
                        </td>
                        <td>{{ item.vehicleModelTh }}</td>
                        <td>{{ ChangeType(item.saleCondition) }}</td>
                        <td class="text-end">
                          {{ Number(item.price).toLocaleString() }}
                        </td>
                        <td class="text-end">{{ item.downPaymentPercent }}</td>
                        <td class="text-end">
                          {{ Number(item.downPayment).toLocaleString() }}
                        </td>
                        <td class="text-end">{{ item.installmentPeriod }}</td>
                        <td class="text-end">
                          {{ Number(item.installmentPayment).toLocaleString() }}
                        </td>

                        <td class="text-center">
                          <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                              <a
                                class="px-2 text-primary"
                                v-b-tooltip.hover
                                title="Edit"
                                @click="geVehicletUpdate(item)"
                              >
                                <i class="uil uil-pen font-size-18"></i>
                              </a>
                              <a
                                class="px-2 text-danger"
                                v-b-tooltip.hover
                                title="Delete"
                                @click="alert(item.ppCusdId)"
                              >
                                <i class="uil uil-trash-alt font-size-18"></i>
                              </a>
                            </li>
                          </ul>
                        </td>
                        <br />
                      </tr>
                      <tr>
                        <td colspan="11" class="text-end">
                          <div
                            class="col-md-auto mb-3"
                            v-if="status == 'SCS' || status == 'C'"
                          >
                            <router-link
                              :to="{
                                name: 'add-branchTestDrive',
                                params: {
                                  dataShow: rowData,
                                  rowVehicle: item,
                                },
                              }"
                              class="btn btn-primary"
                            >
                              นัดหมายทดลองขับ
                            </router-link>
                          </div>
                          <!-- <div class="col-md-12 col-sm-12 mt-3">
                            <button
                              :disabled="
                                item.inquireStatus === 'APV' ||
                                item.inquireStatus === 'SUBMIT'
                              "
                              type="submit"
                              class="btn btn-success mb-3"
                              @click="alertApv0(item.ppCusdId)"
                            >
                              บันทึกสอบถามเครดิต</button
                            >&nbsp;&nbsp;

                            <button
                              :disabled="
                                item.inquireStatus === 'APV' ||
                                item.inquireStatus === 'SUBMIT'
                              "
                              type="submit"
                              class="btn btn-danger mb-3"
                              @click="alertApv1(item.ppCusdId)"
                            >
                              บันทึกสอบถามเครดิตพร้อมอนุมัติ
                            </button>
                          </div> -->
                        </td>
                        <!-- <td colspan="5" class="text-end">
                        
                          <div
                            class="
                              row
                              justify-content-center
                              align-items-center
                            "
                          >
                            <div class="col-md-6 col-sm-12 mt-3">
                              <div class="form-group row mb-4">
                                <label
                                  for="horizontal-firstname-input"
                                  class="col-sm-3 col-md-3 col-form-label"
                                  ><code>* </code> VIN:</label
                                >

                                <div class="col-sm-9 col-md-9">
                                  <label
                                    v-if="item.vin != null"
                                    class="col-form-label"
                                    >{{ item.vin }}</label
                                  >
                                  <input
                                    v-if="item.vin == null"
                                    type="text"
                                    class="form-control"
                                    id="horizontal-firstname-input"
                                    v-model="item.vins"
                                  />
                                  <div class="invalid-feedback">
                                    กรุณาระบุ VIN
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <div class="row align-items-md-center">
                                <div class="col-md-auto col-sm-6 mb-3">
                                  <button
                                    v-if="item.vin == null"
                                    type="submit"
                                    class="btn btn-success ms-3"
                                    @click="FormApv(item.ppCusdId, item.vins)"
                                  >
                                    บันทึกการขาย
                                  </button>
                                </div>
                                <div
                                  class="col-md-auto col-sm-6 mb-3"
                                  v-if="status == 'SCS' || status == 'C'"
                                >
                                  <router-link
                                    :to="{
                                      name: 'add-branchTestDrive',
                                      params: {
                                        dataShow: rowData,
                                        rowVehicle: item,
                                      },
                                    }"
                                    class="btn btn-primary"
                                  >
                                    นัดหมายทดลองขับ
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                        
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <span
                    v-if="this.rowVehicle.length === 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>

                <!-- <form
                  class="needs-validation mt-3"
                  @submit.prevent="FormApvStatus"
                > -->
                <div class="row">
                  <div class="col-md-3 col-sm-4 col-6">
                    <div class="mb-3 position-relative">
                      <label for="status">สถานะ:</label>
                      <multiselect
                        :disabled="
                          this.status == 'SCS' ||
                          this.status == 'C' ||
                          this.status == 'L'
                        "
                        v-model="formApv.status"
                        :options="statusOption"
                        label="nameTh"
                        track-by="id"
                        :show-labels="false"
                        :class="{
                          'is-invalid':
                            submitApvStatus && $v.formApv.status.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitApvStatus && $v.formApv.status.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.formApv.status.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-5 col-6">
                    <div class="mt-2 position-relative">
                      <br />
                      <b-button
                        :disabled="this.status == 'C' || this.status == 'L'"
                        variant="danger"
                        @click="FormApvStatus"
                      >
                        บันทึก
                      </b-button>
                    </div>
                    <!-- <p
                        v-if="this.status == 'C' || this.status == 'L'"
                        class="mt-2"
                      >
                        <code>(บันทึกปิดลูกค้ามุ่งหวังแล้ว)</code>
                      </p> -->
                  </div>
                </div>
                <!-- </form> -->
              </b-skeleton-wrapper>

              <hr />
            </div>
            <!-- end card -->

            <b-modal
              ref="modalVeUpdate"
              :id="'modal-' + this.rowVehicleId"
              title="แก้ไขข้อมูลรถ"
              hide-footer
              size="lg"
              centered
            >
              <div class="card-body">
                <form
                  class="needs-validation"
                  @submit.prevent="vehicleUpdateForm"
                >
                  <div class="row">
                    <h5 class="font-size-14 mb-1">ข้อมูลรถที่ต้องการ</h5>
                    <hr />
                    <div class="col-md-12 col-sm-12">
                      <div class="row align-items-center">
                        <div class="col-12 col-md-12 col-sm-12">
                          <div class="mb-3 position-relative">
                            <label for="prodId">สินค้า:</label>
                            <multiselect
                              id="prodId"
                              v-model="vehicleUpdateform.prodId"
                              :options="proOption"
                              style="ts-15"
                              placeholder=""
                              label="nameTh"
                              :show-labels="false"
                              track-by="prodId"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              :custom-label="customLabelPro"
                              @search-change="getProduct($event)"
                              @input="selectObUp(vehicleUpdateform.prodId)"
                              :class="{
                                'is-invalid':
                                  submitUpdateForm &&
                                  $v.vehicleUpdateform.prodId.$error,
                              }"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                            <div
                              v-if="
                                submitUpdateForm &&
                                $v.vehicleUpdateform.prodId.$error
                              "
                              class="invalid-tooltip"
                            >
                              <span
                                v-if="!$v.vehicleUpdateform.prodId.required"
                              >
                                {{ error }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3 col-sm-3 col-3">
                          <div class="mt-2 position-relative">
                            <b-button
                              class="btn"
                              variant="info"
                              v-b-modal.modalProduct
                            >
                              <i class="uil-file-search-alt"></i
                            ></b-button>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="provinceId">สีภายนอก:</label>
                        <multiselect
                          v-model="vehicleUpdateform.exteriorColorId"
                          track-by="exteriorColorId"
                          :options="exteriorColorOption"
                          :custom-label="customLabelExColer"
                          label="nameTh"
                          :show-labels="false"
                          @search-change="getExteriorColor($event)"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="provinceId">สีภายใน:</label>
                        <multiselect
                          v-model="vehicleUpdateform.interiorColorId"
                          track-by="interiorColorId"
                          :options="interiorColorOption"
                          label="nameTh"
                          :custom-label="customLabelInColer"
                          :show-labels="false"
                          @search-change="getInteriorColor($event)"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <div class="mb-3 position-relative">
                        <label for="note">หมายเหตุ:</label>

                        <textarea
                          v-model="vehicleUpdateform.note"
                          id="formmessage"
                          rows="3"
                          class="form-control"
                        >
                        </textarea>
                      </div>
                    </div>
                    <h5 class="font-size-14 mb-1">ข้อมูลการเงิน</h5>
                    <hr />
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="provinceId">ประเภทการชำระ:</label>
                        <multiselect
                          @select="disEdit"
                          v-model="vehicleUpdateform.saleCondition"
                          track-by="nameTh"
                          :options="saleConditionOption"
                          label="nameTh"
                          :show-labels="false"
                          :class="{
                            'is-invalid':
                              submitUpdateForm &&
                              $v.vehicleUpdateform.saleCondition.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="
                            submitUpdateForm &&
                            $v.vehicleUpdateform.saleCondition.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="!$v.vehicleUpdateform.saleCondition.required"
                          >
                            {{ error }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="price">ราคา:</label>
                        <input
                          :disabled="isDisableEdit"
                          v-model="vehicleUpdateform.price"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="downPayment">เงินดาวน์(%):</label>
                        <input
                          :disabled="isDisableEdit"
                          v-model="vehicleUpdateform.downPaymentPer"
                          type="text"
                          class="form-control"
                          placeholder=""
                          @input="calculate()"
                        />
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="downPayment">เงินดาวน์(บาท):</label>
                        <input
                          :disabled="isDisableEdit"
                          v-model="vehicleUpdateform.downPayment"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="installmentPeriod">จำนวนงวด:</label>
                        <input
                          :disabled="isDisableEdit"
                          v-model="vehicleUpdateform.installmentPeriod"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="installmentPayment">ค่างวด:</label>
                        <input
                          :disabled="isDisableEdit"
                          v-model="vehicleUpdateform.installmentPayment"
                          type="text"
                          class="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="text-end">
                    <button type="submit" class="btn btn-success">
                      บันทึกการแก้ไข
                    </button>
                  </div>
                </form>
              </div>
            </b-modal>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  required,
  email,
  minLength,
  // sameAs,
  maxLength,
  // minValue,
  // maxValue,
  numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import tableData from "@/components/tablecomponent";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
    DatePicker,
    // tableData,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: appConfig.prospectCustomer,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      Hide: false,
      enabled: false,
      submitform: false,
      submitformAddress: false,
      modalUpdateAddress: false,
      submitUpdateAddress: false,

      title: "แก้ไขข้อมูลลูกค้ามุ่งหวัง",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: appConfig.prospectCustomer,
          href: "/prospect-customer",
        },
        {
          text: "แก้ไขข้อมูลลูกค้ามุ่งหวัง",
          active: true,
        },
      ],

      totalPagePro: "",
      perPagePro: 10,
      currentPagePro: 1,
      totalRecordPro: "",
      pageOptionsPro: [5, 10, 25, 50, 100],

      localDataBranch: [], //เป็น-array Id
      localData: [],
      value: null,
      value1: null,
      dataTest: [],
      options: [],
      ppCusId: this.$route.params.ppCusId,
      startIndex: "",
      endIndex: "",
      loading: false,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",
      overlayFlag: false,
      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],
      statusOption: [
        {
          nameTh: "ซื้อ",
          id: "C",
        },
        {
          nameTh: "Lost",
          id: "L",
        },
      ],
      fields: [
        {
          key: "index",

          label: "ลำดับ",
        },
        {
          key: "productNameTh",
          sortable: true,
          label: "สินค้า",
        },

        {
          key: "vehicleBrandTh",
          sortable: true,
          label: "ยี่ห้อ",
        },
        {
          key: "vehicleModelTh",
          sortable: true,
          label: "รุ่น",
        },

        {
          key: "saleCondition",
          sortable: true,
          label: "ประเภทการชำระ",
        },
        {
          key: "price",
          sortable: true,
          label: "ราคา",
        },
        {
          key: "downPaymentPercent",
          sortable: true,
          label: "เงินดาวน์(%)",
        },
        {
          key: "downPayment",
          sortable: true,
          label: "เงินดาวน์(บาท)",
        },

        {
          key: "installmentPeriod",
          sortable: true,
          label: "จำนวนงวด",
        },
        {
          key: "installmentPayment",
          sortable: true,
          label: "ค่างวด",
        },
        {
          key: "action",
          label: "แก้ไข|ลบ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",

      lavelOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "อื่นๆ", value: "0" },
      ],
      showDetails: true,
      occOption: [],
      salaryOption: [
        { nameTh: "ต่ำกว่า 10,000", id: 0 },
        { nameTh: "10,001-30,000", id: 1 },
        { nameTh: "30,001-50,000", id: 2 },
        { nameTh: "50,001-100,000", id: 3 },
        { nameTh: "100,000 บาทขึ้นไป", id: 4 },
      ],
      rowVehicles: [],
      modelOptionEdit: [],
      brandOptionEdit: [],
      isDisabled: false,
      isDisableEdit: false,
      rowVehicleId: "",
      modalVeUpdate: false,
      rowVehicle: [],
      cusSrcOption: [],
      prefixOPtion: [],
      provinces: [],
      amphures: [],
      districts: [],
      residentOption: [
        { nameTh: "เช่า", id: "R" },
        { nameTh: "อาศัยอยู่บ้านตัวเอง", id: "H" },
        { nameTh: "อาศัยอยู่กับญาติ", id: "RH" },
        { nameTh: "อาศัยอยู่กับผู้อื่น", id: "OH" },
      ],
      readinessOption: [
        { text: "สูง", value: "3" },
        { text: "กลาง", value: "2" },
        { text: "ต่ำ", value: "1" },
        { text: "ยังไม่พร้อม", value: "0" },
      ],
      maritalOption: [
        { nameTh: "โสด", id: "S" },
        { nameTh: "แต่งงานแล้ว", id: "M" },
        { nameTh: "หย่าร้าง", id: "D" },
        { nameTh: "หม้าย", id: "W" },
      ],
      formApv: {
        status: "",
      },
      vin: "",
      genderOption: [
        { nameTh: "ชาย", id: "M" },
        { nameTh: "หญิง", id: "F" },
        { nameTh: "ไม่ระบุ", id: "NONE" },
      ],
      salarysOption: [],
      eventOption: [],
      srcInOption: [],
      usedBrandOption: [],
      lifestyleOption: [],
      objOption: [],
      familySalaryOption: [],
      croOption: [],
      otherModelOption: [],
      otherVehicleOption: [],
      otherVehicleSubOption: [],
      usedVehicleOption: [],
      customerTypeOption: [
        { nameTh: "ใช้งานส่วนบุคคล ", id: "P" },
        { nameTh: "ใช้งานเชิงธุรกิจ", id: "B" },
      ],
      regPlaceOption: [{ nameTh: "Showroom " }, { nameTh: "Road Show" }],
      form: {
        readiness: "",
        lineId: "",
        facebookId: "",
        houseRegistration: "",
        workPlace: "",
        maritalStatus: "",
        cusId: "",
        residentType: "",

        branchId: "",
        cusType: "",
        groupId: "",
        citizenId: "",
        prefixId: "",
        prefixIdEn: "",
        nameTh: "",
        familyNameTh: "",
        nameEn: "",
        familyNameEn: "",
        birthDate: "",
        mobilePhone: "",
        email: "",
        discount: "",
        credit: "",
        note: "",
        accountPayable: "",
        postCode: "",
        lane: "",
        subLane: "",
        townshipName: "",
        townshipNumber: "",
        buildingNumber: "",
        cusSrcId: "",
        salary: "",
        occId: "",
        level: "",
        salaryId: "",
        eventId: "",
        srcInfoId: "",
        usedBrand: "",
        lifestyleId: "",
        objId: "",
        familySalaryId: "",
        croId: "",
        otherModelId: "",
        otherVehicleTypeId: "",
        otherVehicleSubTypeId: "",
        usedVehicleTypeId: "",
        vehicleLifeYear: "",
        vehicleLifeMonth: "",
        gender: "",
        children: "",
        age: "",
        customerType: "",
        regPlace: "",
        consentPrivate: "",
        consentMarketing: "",
        consentSensitive: "",
        consentTransfer: "",
        eventDate: "",
        firstContactNote: "",
      },
      refProspectCustomer: "",
      refNameTh: "",
      refFamilyNameTh: "",
      refBranchNameTh: "",
      refPhone: "",
      refEmail: "",
      status: "",
      provinceId: "",
      amphurId: "",
      districtId: "",
      gearSystemOption: [],
      saleConditionOption: [
        { nameTh: "เงินสด", id: "C" },
        { nameTh: "ผ่อนชำระ", id: "I" },
      ],
      ppCusdId: "",
      interiorColorOption: [],
      exteriorColorOption: [],
      brandOption: [],
      modelOption: [],
      proOption: [],
      vehicleform: {
        prodId: "",
        price: "",
        downPaymentPer: "",
        brandId: "",
        modelId: "",
        interiorColorId: "",
        exteriorColorId: "",
        gearSystemId: "",
        modelYear: "",
        saleCondition: "",
        downPayment: "",
        installmentPayment: "",
        installmentPeriod: "",
        note: "",

        // road: "",
      },
      transition: "",
      submitApv: false,
      submitApvStatus: false,
      submitVehicleForm: false,
      submitUpdateForm: false,
      vehicleUpdateform: {
        prodId: "",
        price: "",
        downPaymentPer: "",
        brandId: "",
        modelId: "",
        interiorColorId: "",
        exteriorColorId: "",
        gearSystemId: "",
        modelYear: "",
        saleCondition: "",
        downPayment: "",
        installmentPayment: "",
        installmentPeriod: "",
        note: "",

        // road: "",
      },
    };
  },
  validations: {
    form: {
      nameTh: {
        required,
      },
      citizenId: {
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
      mobilePhone: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10),
      },
      email: {
        email,
        maxLength: maxLength(255),
      },
      postCode: {
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      cusSrcId: {
        required,
      },
    },

    vehicleform: {
      saleCondition: {
        required,
      },
      prodId: {
        required,
      },
    },
    formApv: {
      status: {
        required,
      },
    },
    vehicleUpdateform: {
      saleCondition: {
        required,
      },
      prodId: {
        required,
      },
    },
    item: {
      vin: {
        required,
      },
    },
  },
  computed: {
    fullAddress: function () {
      //

      return `${
        this.form.buildingNumber != null ? "" + this.form.buildingNumber : ""
      } ${
        this.form.townshipNumber != null
          ? "หมู่" + " " + this.form.townshipNumber
          : ""
      } ${this.form.townshipName != null ? "" + this.form.townshipName : ""} ${
        this.form.subLane != null ? "ตรอก" + " " + this.form.subLane : ""
      } ${this.form.lane != null ? "ซอย" + " " + this.form.lane : ""} ${
        this.form.streetName != "" && this.form.streetName != undefined
          ? "ถนน" + " " + this.form.streetName
          : ""
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId != undefined &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != "" &&
        this.provinceId.nameTh != undefined
          ? this.provinceId.nameTh
          : ""
      }  ${
        this.form.postCode != null && this.form.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.form.postCode
          : ""
      }
      `;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    this.localData = user.userAccessBranch;
    this.branchItem = user.branchId;

    const localDataBranch = [];
    this.localData.forEach((item) => {
      localDataBranch.push(item.branchId);
    });

    this.localDataBranch = localDataBranch;
    this.getDataShow();
    this.getPrefixes();
    this.getProvinces();
  },
  created() {},
  methods: {
    beforeTabSwitch: function (s) {
      console.log(s);
      if (s === 0) {
        if (this.name.length > 2) {
          return true;
        } else {
          this.formError = true;
          return false;
        }
      }
      return true;
    },
    validateNextTab(props) {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        props.nextTab();
      }
    },
    customLabelCro({ nameTh, familyName }) {
      return `${nameTh}  ${familyName ? familyName : ""}`;
    },
    customLabel({ nameTh, modelCode }) {
      return `${nameTh != null ? nameTh : ""} -[${
        modelCode != null ? modelCode : " "
      }]`;
    },
    calculate() {
      let downPayment = 0;
      downPayment =
        this.vehicleform.price * (this.vehicleform.downPaymentPer / 100);
      this.vehicleform.downPayment = downPayment.toFixed(2);

      let downPaymentV = 0;
      downPaymentV =
        this.vehicleUpdateform.price *
        (this.vehicleUpdateform.downPaymentPer / 100);
      this.vehicleUpdateform.downPayment = downPaymentV.toFixed(2);
    },
    customLabelPro({ nameTh, prodCode }) {
      return `${nameTh != null ? nameTh : ""} ${
        prodCode != null ? "-[" + prodCode + "]" : " "
      }`;
    },
    customLabelExColer({ nameTh, colorCode }) {
      return `${nameTh != null ? nameTh : ""} ${
        colorCode != null ? "-[" + colorCode + "]" : " "
      }`;
    },
    customLabelInColer({ nameTh, colorCode }) {
      return `${nameTh != null ? nameTh : ""} ${
        colorCode != null ? "-[" + colorCode + "]" : " "
      }`;
    },
    selectOb(data) {
      this.vehicleform.price = data.price;
    },
    selectObUp(data) {
      this.vehicleUpdateform.price = data.price;
    },
    ChangeType(nameTh) {
      if (nameTh === "I") {
        return "ผ่อนชำระ";
      } else if (nameTh === "C") {
        return "เงินสด";
      }
    },
    ChangeGender(nameTh) {
      if (nameTh === "M") {
        return "ชาย";
      } else if (nameTh === "F") {
        return "หญิง";
      } else if (nameTh === "NONE") {
        return "ไม่ระบุ";
      }
    },
    ChangeCustomerType(nameTh) {
      if (nameTh === "P") {
        return "ใช้งานส่วนบุคคล";
      } else if (nameTh === "B") {
        return "ใช้งานเชิงธุรกิจ";
      }
    },
    ChangeNameTh(nameTh) {
      if (nameTh.nameTh === "นาย") {
        this.form.prefixIdEn = "Mr.";
      } else if (nameTh.nameTh === "นาง") {
        this.form.prefixIdEn = "Mrs.";
      } else {
        this.form.prefixIdEn = "Miss.";
      }
    },
    dis(option) {
      if (option.id === "C") {
        this.isDisabled = true;
        this.vehicleform.downPaymentPer = 0;
        this.vehicleform.downPayment = 0;
        this.vehicleform.installmentPayment = 0;
        this.vehicleform.installmentPeriod = 0;
      } else if (option.id === "I" || option == null) {
        this.isDisabled = false;
      }
    },
    disEdit(option) {
      if (option.id === "C") {
        this.isDisableEdit = true;
        this.vehicleUpdateform.downPaymentPer = 0;
        this.vehicleUpdateform.downPayment = 0;
        this.vehicleUpdateform.installmentPayment = 0;
        this.vehicleUpdateform.installmentPeriod = 0;
      } else if (option.id === "I" || option == null) {
        this.isDisableEdit = false;
      }
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },
    // FormApv(id, vin) {
    //   this.submitApv = true;
    //   if (vin != null) {
    //     this.postVin(id, vin);
    //   } else {
    //     Swal.fire(
    //       appConfig.swal.title.error,
    //       "กรุณาระบุ VIN",
    //       appConfig.swal.type.error
    //     );
    //   }
    // },
    FormApvStatus() {
      this.$v.$touch();
      this.submitApvStatus = true;
      if (this.$v.formApv.$invalid != true) {
        this.submit();
      }
    },
    Form() {
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.form.$invalid != true) {
        this.putProspectCustomer();
      }
    },
    vehicleForm() {
      this.$v.$touch();
      this.submitVehicleForm = true;
      if (this.$v.vehicleform.$invalid != true) {
        this.postVehicle();
      }
    },
    vehicleUpdateForm() {
      this.$v.$touch();
      this.submitUpdateForm = true;
      if (this.$v.vehicleUpdateform.$invalid != true) {
        this.putVehicle();
      }
    },
    selectYearAdd(data) {
      this.vehicleform.modelYear = data.modelYear;
    },
    selectYearEdit(data) {
      this.vehicleUpdateform.modelYear = data.modelYear;
    },
    postVin: function (id, vin) {
      this.overlayFlag = true;

      useNetw
        .post("api/prospect-customer/sale/store", {
          ppCusdId: id,
          vin: vin,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.getVehicle();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submit: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/prospect-customer/submit", {
          ppCusId: atob(this.ppCusId),
          status: this.formApv.status.id,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.$router.push({
            name: "prospect-customer",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getVehicleBrand: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-vehicle-brand", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.brandOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getVehiclemodel: function (isReset) {
      // this.overlayFlag = true;

      if (this.vehicleform.brandId != null) {
        useNetw
          .get("api/prospect-customer/branch-vehicle-model", {
            params: {
              page: this.currentPage,
              perPage: this.perPage,
              branchId: this.form.branchId.branchId,
              brandId: this.vehicleform.brandId.brandId,
              nameTh: this.vehicleform.modelId,
            },
          })
          .then((response) => {
            this.modelOption = response.data.data;
            this.perPage = response.data.perPage;
            this.from = response.data.from;
            this.totalRecord = response.data.total;
            this.to = response.data.to;
            this.totalPage = Math.ceil(this.totalRecord / this.perPage);
            if (isReset) {
              this.vehicleform.modelId = null;
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              Swal.fire(
                appConfig.swal.title.error,
                JSON.stringify(appConfig.swal.title.Unauthorization),
                // appConfig.swal.title.Unauthorization,
                appConfig.swal.type.error
                // appConfig.swal.title.Unauthorization
              );
              this.$router.push("/login");
            } else {
              Swal.fire(
                appConfig.swal.title.error,
                JSON.stringify(
                  err.message + "<br>" + err.response.data.message
                ),
                appConfig.swal.type.error
              );
            }
          })
          .then(() => {
            // this.overlayFlag = false;
          });
      }
    },
    getVehicleBrandEdit: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-vehicle-brand", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.brandOptionEdit = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getVehiclemodelEdit: function (isReset) {
      // this.overlayFlag = true;

      if (this.vehicleUpdateform.brandId != null) {
        useNetw
          .get("api/prospect-customer/branch-vehicle-model", {
            params: {
              page: this.currentPage,
              perPage: this.perPage,
              branchId: this.form.branchId.branchId,
              brandId: this.vehicleUpdateform.brandId.brandId,
              nameTh: this.vehicleUpdateform.modelId,
            },
          })
          .then((response) => {
            this.modelOptionEdit = response.data.data;
            this.perPage = response.data.perPage;
            this.from = response.data.from;
            this.totalRecord = response.data.total;
            this.to = response.data.to;
            this.totalPage = Math.ceil(this.totalRecord / this.perPage);
            if (isReset) {
              this.vehicleUpdateform.modelId = null;
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              Swal.fire(
                appConfig.swal.title.error,
                JSON.stringify(appConfig.swal.title.Unauthorization),
                // appConfig.swal.title.Unauthorization,
                appConfig.swal.type.error
                // appConfig.swal.title.Unauthorization
              );
              this.$router.push("/login");
            } else {
              Swal.fire(
                appConfig.swal.title.error,
                JSON.stringify(
                  err.message + "<br>" + err.response.data.message
                ),
                appConfig.swal.type.error
              );
            }
          })
          .then(() => {
            // this.overlayFlag = false;
          });
      }
    },

    getOccupations: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-occupation", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.occOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getGearSystems: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/gear-systems", {
          nameTh: event,
        })
        .then((response) => {
          this.gearSystemOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getCustomerSource: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-customer-source", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.cusSrcOption = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getExteriorColor: function (color) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-exterior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.form.branchId.branchId,
            nameTh: color,
          },
        })
        .then((response) => {
          this.exteriorColorOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getInteriorColor: function (color) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-interior-color", {
          params: {
            page: this.currentPage,
            perPage: 100,
            branchId: this.form.branchId.branchId,
            nameTh: color,
          },
        })
        .then((response) => {
          this.interiorColorOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getProduct: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-product", {
          params: {
            page: this.currentPagePro,
            perPage: this.perPagePro,
            branchId: this.form.branchId.branchId,
            image: 0,
            nameTh: event,
          },
        })
        .then((response) => {
          this.proOption = response.data.data;
          this.perPagePro = response.data.perPage;
          this.from = response.data.from;
          this.totalRecordPro = response.data.total;
          this.to = response.data.to;
          this.totalPagePro = Math.ceil(this.totalRecordPro / this.perPagePro);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getSalary: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-salary", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.salarysOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getEvent: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-sales-event", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.eventOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getSrcIn: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-customer-source-info", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.srcInOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUsedBrand: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/brands", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.usedBrandOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getLifestyle: function (event) {
      // this.overlayFlag = true;

      useNetw
        .get("api/master/lifestyles", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.lifestyleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getObj: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/buyer-objectives", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.objOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getFamilySalary: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-salary", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.familySalaryOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getCro: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/branch-user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            name: event,
          },
        })
        .then((response) => {
          this.croOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOtherModel: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/dealer-other-vehicle-model", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.form.branchId.branchId,
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherModelOption = response.data.data;

          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOtherVehicle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/types", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherVehicleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getOtherVehicleSub: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle-subtypes", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.otherVehicleSubOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUsedVehicle: function (event) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/vehicle/types", {
          params: {
            nameTh: event,
          },
        })
        .then((response) => {
          this.usedVehicleOption = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getDataShow: function () {
      this.overlayFlag = true;

      useNetw
        .get("api/prospect-customer/show", {
          params: {
            ppCusId: atob(this.ppCusId),
          },
        })
        .then((response) => {
          this.rowData = response.data.data;
          this.branchId = response.data.data.branchId;
          this.form.branchId = {
            nameTh: response.data.data.branchNameTh,
            branchId: response.data.data.branchId,
          };

          this.form.citizenId = response.data.data.citizenId;
          this.form.prefixId = {
            nameTh: response.data.data.prefixNameTh,
            prefixId: response.data.data.prefixId,
          };
          this.form.prefixIdEn = response.data.data.prefixNameEn;
          this.form.nameTh = response.data.data.nameTh;
          this.form.familyNameTh = response.data.data.familyNameTh;
          this.form.nameEn = response.data.data.nameEn;
          this.form.familyNameEn = response.data.data.familyNameEn;
          this.form.birthDate = response.data.data.birthDate;
          this.form.mobilePhone = response.data.data.mobilePhone;
          this.form.email = response.data.data.email;
          this.form.note = response.data.data.note;
          this.form.buildingNumber = response.data.data.buildingNumber;
          this.form.townshipNumber = response.data.data.townshipNumber;
          this.form.townshipName = response.data.data.townshipName;
          this.form.subLane = response.data.data.subLane;
          this.form.lane = response.data.data.lane;
          this.form.streetName = response.data.data.streetName;
          this.form.postCode = response.data.data.postCode;
          this.districtId = {
            nameTh: response.data.data.districtNameTh,
            districtId: response.data.data.districtId,
          };
          this.provinceId = {
            nameTh: response.data.data.provinceNameTh,
            provinceId: response.data.data.provinceId,
          };
          this.amphurId = {
            nameTh: response.data.data.amphurNameTh,
            amphureId: response.data.data.amphurId,
          };
          this.form.cusSrcId = {
            nameTh: response.data.data.cusSrcNameTh,
            cusSrcId: response.data.data.cusSrcId,
          };
          this.form.salary = {
            nameTh: this.fixSarary(response.data.data.salary),
            id: response.data.data.salary,
          };
          this.form.occId = {
            nameTh: response.data.data.occNameTh,
            occId: response.data.data.occId,
          };
          this.form.level = response.data.data.level;
          this.refProspectCustomer = response.data.data.refProspectCustomer;
          if (this.refProspectCustomer !== null) {
            this.refNameTh = response.data.data.refProspectCustomer.name_th;
            this.refFamilyNameTh =
              response.data.data.refProspectCustomer.family_name_th;
            this.refBranchNameTh =
              response.data.data.refProspectCustomer.branchNameTh;
            this.refPhone = response.data.data.refProspectCustomer.mobile_phone;
            this.refEmail = response.data.data.refProspectCustomer.email;
          }

          this.status = response.data.data.status;
          this.form.residentType = {
            nameTh: this.fixResidentType(response.data.data.residentType),
            id: response.data.data.residentType,
          };
          this.form.maritalStatus = {
            nameTh: this.fixMaritalStatus(response.data.data.maritalStatus),
            id: response.data.data.maritalStatus,
          };
          this.form.workPlace = response.data.data.workplace;
          this.form.houseRegistration = response.data.data.houseRegistration;
          this.form.facebookId = response.data.data.facebookId;
          this.form.lineId = response.data.data.lineId;
          this.form.readiness = response.data.data.readiness;
          this.form.salaryId = {
            nameTh: response.data.data.personSalaryTh,
            salaryId: response.data.data.salaryId,
          };
          this.form.eventId = {
            nameTh: response.data.data.eventNameTh,
            eventId: response.data.data.eventId,
          };
          this.form.srcInfoId = {
            nameTh: response.data.data.customerSourceInfoTh,
            srcInfoId: response.data.data.srcInfoId,
          };
          this.form.usedBrand = {
            nameTh: response.data.data.useBrandTh,
            brandId: response.data.data.usedBrand,
          };
          this.form.lifestyleId = {
            nameTh: response.data.data.lifeStyleTh,
            lifestyleId: response.data.data.lifestyleId,
          };
          this.form.objId = {
            nameTh: response.data.data.buyerObjectiveTh,
            objId: response.data.data.objId,
          };
          this.form.familySalaryId = {
            nameTh: response.data.data.familySalaryTh,
            salaryId: response.data.data.familySalaryId,
          };
          this.form.croId = {
            nameTh: response.data.data.croNameTh,
            userId: response.data.data.croId,
          };
          this.form.otherModelId = {
            nameTh: response.data.data.otherVehicleModelTh,
            modelId: response.data.data.otherModelId,
          };
          this.form.otherVehicleTypeId = {
            nameTh: response.data.data.otherVehicleTypeTh,
            typeId: response.data.data.otherVehicleTypeId,
          };
          this.form.otherVehicleSubTypeId = {
            nameTh: response.data.data.otherVehicleSubTypeTh,
            subTypeId: response.data.data.otherVehicleSubTypeId,
          };
          this.form.usedVehicleTypeId = {
            nameTh: response.data.data.useVehicleTypeTh,
            typeId: response.data.data.usedVehicleTypeId,
          };
          this.form.vehicleLifeYear = response.data.data.vehicleLifeYear;
          this.form.vehicleLifeMonth = response.data.data.vehicleLifeMonth;
          this.form.gender = {
            nameTh: this.ChangeGender(response.data.data.gender),
            id: response.data.data.gender,
          };
          this.form.children = response.data.data.children;
          this.form.age = response.data.data.age;
          this.form.customerType = {
            nameTh: this.ChangeCustomerType(response.data.data.customerType),
            id: response.data.data.customerType,
          };
          this.form.regPlace = { nameTh: response.data.data.regPlace };
          this.form.consentPrivate = response.data.data.consentPrivate;
          this.form.consentMarketing = response.data.data.consentMarketing;
          this.form.consentSensitive = response.data.data.consentSensitive;
          this.form.consentTransfer = response.data.data.consentTransfer;
          this.form.eventDate = response.data.data.eventDate;
          this.form.firstContactNote = response.data.data.firstContactNote;
          this.getVehicle();
          this.getCustomerSource();
          // this.getVehicleBrand();
          this.getOccupations();
          this.getVehicleBrandEdit();
          // this.getGearSystems();
          this.getExteriorColor();
          this.getInteriorColor();
          this.getProduct();
          this.getSalary();
          this.getEvent();
          this.getSrcIn();
          this.getUsedBrand();
          this.getLifestyle();
          this.getObj();
          this.getFamilySalary();
          this.getCro();
          this.getOtherModel();
          this.getOtherVehicle();
          this.getOtherVehicleSub();
          this.getUsedVehicle();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    fixSarary(id) {
      if (id == "0") {
        return "ต่ำกว่า 10,000";
      } else if (id == "1") {
        return "10,001-30,000";
      } else if (id == "2") {
        return "30,001-50,000";
      } else if (id == "3") {
        return "50,001-100,000";
      } else if (id == "4") {
        return "100,000 บาทขึ้นไป";
      }
    },
    fixPayment(gId) {
      if (gId == "C") {
        return "เงินสด";
      } else if (gId == "I") {
        return "ผ่อนชำระ";
      }
    },
    fixResidentType(data) {
      if (data == "R") {
        return "เช่า";
      } else if (data == "H") {
        return "อาศัยอยู่บ้านตัวเอง";
      } else if (data == "RH") {
        return "อาศัยอยู่กับญาติ";
      } else if (data == "OH") {
        return "อาศัยอยู่กับผู้อื่น";
      }
    },
    fixMaritalStatus(data) {
      if (data == "S") {
        return "โสด";
      } else if (data == "M") {
        return "แต่งงานแล้ว";
      } else if (data == "D") {
        return "หย่าร้าง";
      } else if (data == "W") {
        return "หม้าย";
      }
    },

    geVehicletUpdate(rowVehicleId) {
      this.rowVehicleId = rowVehicleId;

      this.$bvModal.show(`modal-${rowVehicleId}`);
      this.ppCusdId = rowVehicleId.ppCusdId;
      this.vehicleUpdateform.prodId = {
        nameTh: rowVehicleId.productNameTh,
        prodId: rowVehicleId.prodId,
      };

      this.vehicleUpdateform.exteriorColorId = {
        nameTh: rowVehicleId.exteriorColorNameTh,
        exteriorColorId: rowVehicleId.exteriorColorId,
        colorCode: rowVehicleId.colorCode,
      };
      this.vehicleUpdateform.interiorColorId = {
        nameTh: rowVehicleId.interiorColorNameTh,
        interiorColorId: rowVehicleId.interiorColorId,
        colorCode: rowVehicleId.colorCode,
      };
      this.vehicleUpdateform.price = rowVehicleId.price;
      this.vehicleUpdateform.downPaymentPer = rowVehicleId.downPaymentPercent;
      this.vehicleUpdateform.note = rowVehicleId.note;
      this.vehicleUpdateform.saleCondition = {
        nameTh: this.fixPayment(rowVehicleId.saleCondition),
        id: rowVehicleId.saleCondition,
      };
      if (rowVehicleId.saleCondition == "I") {
        this.vehicleUpdateform.downPayment = rowVehicleId.downPayment;
        this.vehicleUpdateform.installmentPayment =
          rowVehicleId.installmentPayment;
        this.vehicleUpdateform.installmentPeriod =
          rowVehicleId.installmentPeriod;
        this.isDisableEdit = false;
      } else if (rowVehicleId.saleCondition == "C") {
        this.vehicleUpdateform.downPayment = 0;
        this.vehicleUpdateform.installmentPayment = 0;
        this.vehicleUpdateform.installmentPeriod = 0;
        this.isDisableEdit = true;
      }
    },

    getVehicle: function () {
      this.loading = true; //skeleton true
      useNetw
        .get("api/prospect-customer/vehicle", {
          params: {
            ppCusId: atob(this.ppCusId),
          },
        })
        .then((response) => {
          this.rowVehicle = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    handleChangePage(page) {
      this.currentPage = page;
      this.getVehicle();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getVehicle();
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getProvinces: function () {
      //  if(this.provinceId != null && this.provinceId != "" && this.provinceId != undefined) {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
      //  }
    },
    getAmphures: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null
      ) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId ? this.provinceId.provinceId : "",
            },
          })
          .then((response) => {
            this.amphures = response.data.data;

            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            this.loading = false; //skeleton false
          });
      }
    },
    getDistricts: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null &&
        this.amphurId != null &&
        this.amphurId != ""
      ) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.amphurId ? this.amphurId.amphureId : "",
            },
          })
          .then((response) => {
            this.districts = response.data.data;
            if (isReset) {
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },

    getPrefixes: function () {
      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    putProspectCustomer: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/prospect-customer/update", {
          ppCusId: atob(this.ppCusId),
          citizenId: this.form.citizenId,
          prefixId:
            this.form.prefixId.prefixId != undefined
              ? this.form.prefixId.prefixId
              : "",
          nameTh: this.form.nameTh,
          familyNameTh: this.form.familyNameTh,
          nameEn: this.form.nameEn,
          familyNameEn: this.form.familyNameEn,
          birthDate: this.form.birthDate,
          mobilePhone: this.form.mobilePhone,
          email: this.form.email,
          note: this.form.note,
          buildingNumber: this.form.buildingNumber,
          townshipNumber: this.form.townshipNumber,
          townshipName: this.form.townshipName,
          subLane: this.form.subLane,
          lane: this.form.lane,
          streetName: this.form.streetName,
          districtId: this.districtId ? this.districtId.districtId : "",
          amphurId: this.amphurId ? this.amphurId.amphureId : "",
          provinceId: this.provinceId ? this.provinceId.provinceId : "",
          postCode: this.form.postCode,
          fullAddress: this.fullAddress,
          cusSrcId: this.form.cusSrcId ? this.form.cusSrcId.cusSrcId : "",
          salary: this.form.salary.id ? this.form.salary.id : "",
          occId: this.form.occId ? this.form.occId.occId : "",
          level: this.form.level,
          residentType: this.form.residentType ? this.form.residentType.id : "",
          maritalStatus: this.form.maritalStatus
            ? this.form.maritalStatus.id
            : "",
          workPlace: this.form.workPlace,
          houseRegistration: this.form.houseRegistration,
          facebookId: this.form.facebookId,
          lineId: this.form.lineId,
          readiness: this.form.readiness,
          salaryId: this.form.salaryId ? this.form.salaryId.salaryId : "",
          eventId: this.form.eventId ? this.form.eventId.eventId : "",
          srcInfoId: this.form.srcInfoId ? this.form.srcInfoId.srcInfoId : "",
          usedBrand: this.form.usedBrand ? this.form.usedBrand.brandId : "",
          lifestyleId: this.form.lifestyleId
            ? this.form.lifestyleId.lifestyleId
            : "",
          objId: this.form.objId ? this.form.objId.objId : "",
          familySalaryId: this.form.familySalaryId
            ? this.form.familySalaryId.salaryId
            : "",
          croId: this.form.croId ? this.form.croId.userId : "",
          otherModelId: this.form.otherModelId
            ? this.form.otherModelId.modelId
            : "",
          otherVehicleTypeId: this.form.otherVehicleTypeId
            ? this.form.otherVehicleTypeId.typeId
            : "",
          otherVehicleSubTypeId: this.form.otherVehicleSubTypeId
            ? this.form.otherVehicleSubTypeId.subTypeId
            : "",
          usedVehicleTypeId: this.form.usedVehicleTypeId
            ? this.form.usedVehicleTypeId.typeId
            : "",
          vehicleLifeYear: this.form.vehicleLifeYear,
          vehicleLifeMonth: this.form.vehicleLifeMonth,
          gender: this.form.gender ? this.form.gender.id : "",
          children: this.form.children,
          age: this.form.age,
          customerType: this.form.customerType ? this.form.customerType.id : "",
          regPlace: this.form.regPlace.nameTh,
          consentPrivate: this.form.consentPrivate,
          consentMarketing: this.form.consentMarketing,
          consentSensitive: this.form.consentSensitive,
          consentTransfer: this.form.consentTransfer,
          eventDate: this.form.eventDate,
          firstContactNote: this.form.firstContactNote,
        })
        .then((response) => {
          this.cusIdPost = response.data.cusId;
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.getDataShow();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    clearPost() {
      this.vehicleform.prodId = "";
      this.vehicleform.price = "";
      this.vehicleform.downPaymentPer = "";
      this.vehicleform.brandId = "";
      this.vehicleform.modelId = "";
      this.vehicleform.modelYear = "";
      this.vehicleform.interiorColorId = "";
      this.vehicleform.exteriorColorId = "";
      this.vehicleform.gearSystemId = "";
      this.vehicleform.saleCondition = "";
      this.vehicleform.downPayment = "";
      this.vehicleform.installmentPayment = "";
      this.vehicleform.installmentPeriod = "";
      this.vehicleform.note = "";
      this.submitVehicleForm = false;
    },

    postVehicle: function () {
      this.overlayFlag = true;

      useNetw
        .post("api/prospect-customer/vehicle/store", {
          ppCusId: atob(this.ppCusId),

          interiorColorId: this.vehicleform.interiorColorId
            ? this.vehicleform.interiorColorId.interiorColorId
            : "",
          exteriorColorId: this.vehicleform.exteriorColorId
            ? this.vehicleform.exteriorColorId.exteriorColorId
            : "",

          saleCondition: this.vehicleform.saleCondition.id
            ? this.vehicleform.saleCondition.id
            : "",
          downPayment: this.vehicleform.downPayment,
          installmentPayment: this.vehicleform.installmentPayment,
          installmentPeriod: this.vehicleform.installmentPeriod,
          note: this.vehicleform.note,
          price: this.vehicleform.price,
          downPaymentPercent: this.vehicleform.downPaymentPer,
          prodId: this.vehicleform.prodId ? this.vehicleform.prodId.prodId : "",
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modal-ve"].hide();
          this.getVehicle();
          this.clearPost();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alert(rowVehicle) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteVehicle(rowVehicle);
          }
        });
    },
    alertApv0(rowVehicle) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "บันทึกข้อมูลหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "บันทึก, Yes",
          cancelButtonText: "ไม่บันทึก, No",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit(rowVehicle);
          }
        });
    },
    putSubmit: function (id) {
      this.loading = true;
      useNetw
        .post("api/prospect-customer/credit-inquire/store", {
          ppCusdId: id,
          approve: 0,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.inquireId = response.data.inquireId;
          this.$router.push({
            name: "detail-credit-inquire",
            params: {
              inquireId: btoa(response.data.inquireId),
            },
          });
          this.getVehicle();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },

    alertApv1(rowVehicle) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "อนุมัติทันทีหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "อนุมัติ, Yes",
          cancelButtonText: "ไม่อนุมัติ, No",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putApv(rowVehicle);
          }
        });
    },
    putApv: function (id) {
      this.loading = true;
      useNetw
        .post("api/prospect-customer/credit-inquire/store", {
          ppCusdId: id,
          approve: 1,
        })
        .then((response) => {
          this.getVehicle();
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    ConfirmdeleteAddress(ArrId) {
      Swal.fire({
        title: "ต้องการลบที่อยู่นี้ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.deleteAddress(ArrId);
        }
      });
    },
    deleteVehicle: function (rowVehicle) {
      this.rowVehicle = rowVehicle;
      this.loading = true; //skeleton true
      useNetw
        .delete("api/prospect-customer/vehicle/delete", {
          data: {
            ppCusdId: this.rowVehicle,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getVehicle();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    putVehicle: function () {
      this.loading = true;
      useNetw
        .put("api/prospect-customer/vehicle/update", {
          ppCusdId: this.ppCusdId,

          interiorColorId: this.vehicleUpdateform.interiorColorId
            ? this.vehicleUpdateform.interiorColorId.interiorColorId
            : "",
          exteriorColorId: this.vehicleUpdateform.exteriorColorId
            ? this.vehicleUpdateform.interiorColorId.exteriorColorId
            : "",

          saleCondition: this.vehicleUpdateform.saleCondition
            ? this.vehicleUpdateform.saleCondition.id
            : "",
          downPayment: this.vehicleUpdateform.downPayment,
          installmentPayment: this.vehicleUpdateform.installmentPayment,
          installmentPeriod: this.vehicleUpdateform.installmentPeriod,
          note: this.vehicleUpdateform.note,
          price: this.vehicleUpdateform.price,
          downPaymentPercent: this.vehicleUpdateform.downPaymentPer,
          prodId: this.vehicleUpdateform.prodId
            ? this.vehicleUpdateform.prodId.prodId
            : "",
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs.modalVeUpdate.hide();
          this.getVehicle();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleSearchPro() {
      if (this.form.branchId === "") {
        this.branchId;
      } else {
        this.branchId = this.form.branchId ? this.form.branchId.branchId : "";
      }
      this.getProduct();
    },
    handleChangePagePro(page) {
      this.currentPagePro = page;
      this.getProduct();
    },
    handlePageChangePro(active) {
      this.perPagePro = active;
      this.currentPagePro = 1;
      this.getProduct();
    },
  },
};
</script>
<style scoped>
.input-placeholder {
  font-size: 12px;
}
.width {
  width: 200px;
}
.width-input {
  width: 300px;
}
.br {
  padding-right: 20px;
}
.b-form-radio-group .label {
  margin-left: 2px;
}
</style>
